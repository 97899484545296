import React from "react";

const Altar = (props) => {
  return (
    <div className="altar-ethos">
      {/* <div>{props.albumcover}</div> */}
      <div className="altar-ethos-music">
        {props.artist} - {props.song}
      </div>
    </div>
  );
};

export default Altar;
