import React from "react";

import {
  BrowserRouter as Router,
  // Redirect,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

import "../src/css/style.css";

import MusicChapel from "./courtyard/MusicChapel.js";
import Hellohi from "./courtyard/Hellohi.js";

const App = () => {
  let theRoutes = (
    <Routes>
      <Route
        path="/"
        element={
          <Navigate to="/sweetluvnews-journalofthepress-numberonesongs" />
        }
      />
      <Route
        path="/sweetluvnews-journalofthepress-numberonesongs"
        element={<MusicChapel />}
      />
      {/* <Route path="/hihello" element={<Hellohi />} /> */}
    </Routes>
  );
  return (
    <Router>
      <div>{theRoutes}</div>
    </Router>
  );
};

export default App;
